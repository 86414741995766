import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { CasesOverviewFragment, CasesPageFragment, CasesPageQuery } from '../entities/operationResults';
import Meta from './atoms/Meta';
import OverviewContainer from './organisms/OverviewContainer';
import { removeTags } from '../utils/removeTags';

export const query = graphql`
    query CasesPageQuery($site: String, $uri: String) {
        craft {
            entries(site: $site, type: Case, hasCasePage: true) {
                ...CasesOverviewFragment
            }
            entry(site: $site, uri: $uri) {
                ...CasesPageFragment
            }
        }
    }

    fragment CasesOverviewFragment on Craft_Case {
        __typename
        title
        uri
        slug
        id
        caseImage {
            ...CaseImageFragment
        }
        tint {
            hex
        }
        seo {
            ...MetaFragment
        }
    }
`;

export const fragment = graphql`
    fragment CasesPageFragment on Craft_Cases {
        title
        advancedDescription {
            content
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    in?: boolean;
    data: CasesPageQuery;
}

const Cases: FC<Props> = ({ data, ...props }) => {
    const cases = data.craft.entries?.filter((caseItem): caseItem is CasesOverviewFragment => !!caseItem) ?? [];
    const entry = data.craft.entry as CasesPageFragment;

    if (!entry) {
        return null;
    }
    const { seo, title, advancedDescription } = entry;

    const meta = seo && seo[0];
    const description = advancedDescription && advancedDescription.content;
    const metaDescription = description && removeTags(description);

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : metaDescription}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <OverviewContainer in={props.in} data={cases} />
        </>
    );
};

export default Cases;
